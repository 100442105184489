const DetailsHeader = ({ document }) => {
  return (
    <div
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${document?.imageUrl})`,
        height: '35vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className=' w-full flex items-center h-full justify-center'>
        <h1 className=' text-[2.5rem] text-[#fff] font-semibold'>News</h1>
      </div>
    </div>
  );
};

export default DetailsHeader;
