import React from 'react';
import ContactHeaderSection from '../components/Contact/ContactHeaderSection';
import ContactForm from '../components/Contact/ContactForm';
import ContactMap from '../components/Contact/ContactMap';

const Contact = () => {
  return (
    <main>
      <ContactHeaderSection />
      <ContactForm />
      <ContactMap />
    </main>
  );
};

export default Contact;
