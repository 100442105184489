import React from 'react';
import HeaderCarousel from '../components/home/HeaderCarousel';
import NotreMission from '../components/home/NotreMission';
import News from '../components/home/News';
import FooterContact from '../utilities/FooterContact';
import QueFaisonsNous from '../components/home/QueFaisonsNous';

const Home = () => {
  return (
    <main>
      <HeaderCarousel />
      <NotreMission />
      <QueFaisonsNous />
      <FooterContact />
    </main>
  );
};

export default Home;
