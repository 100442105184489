const PagesHeader = ({ desc = '', img, title }) => {
  return (
    <section
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${img})`,
        height: '35vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className=' flex-col w-full flex items-center h-full justify-center'>
        <h1 className=' text-[1.6rem] md:text-[2.5rem] text-[#fff] font-semibold'>
          {title}
        </h1>
        {desc && <p className=' text-[1.2rem] text-white'>{desc} </p>}
      </div>
    </section>
  );
};

export default PagesHeader;
