import { useParams } from 'react-router-dom';
import DetailsContent from '../components/DetailsContent';
import PublicationsHeader from '../components/publications/PublicationsHeader';
import { useCollection } from '../hooks/useCollection';
import { useDocument } from '../hooks/useDocument';
import Spinner from '../utilities/Spinner';

const PublicationsDetails = () => {
  let { id } = useParams();

  const { docResponse } = useDocument('publications', id);
  const { documents, loading, error } = useCollection('publications');
  const { document, isPending } = docResponse;

  if (isPending) {
    return (
      <div className=' items-center flex justify-center h-[70vh] w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
        <Spinner loading={isPending} />
      </div>
    );
  }
  return (
    <div>
      <PublicationsHeader document={document} />
      <DetailsContent document={document} />
    </div>
  );
};

export default PublicationsDetails;
