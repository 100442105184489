import { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, storage } from '../../firebase/config';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

const SendCarriere = () => {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Génération des nombres aléatoires pour la question de validation
  useEffect(() => {
    generateRandomNumbers();
  }, []);

  const generateRandomNumbers = () => {
    setNum1(Math.floor(Math.random() * 10));
    setNum2(Math.floor(Math.random() * 10));
    setUserAnswer('');
    setResultMessage('');
  };

  // Gestion des changements de formulaire
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  // Initialisation du formulaire
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    resume: null,
    coverLetter: '',
  });

  // Fonction de soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResultMessage('');
    setMessage('');
    setErrorMessage('');

    // Validation de la réponse à la question de sécurité
    const correctAnswer = num1 + num2;
    if (parseInt(userAnswer) !== correctAnswer) {
      setErrorMessage('Please enter the correct answer to proceed.');
      return;
    }

    // Validation du fichier CV
    if (!formData.resume) {
      setErrorMessage('Please select a resume file.');
      return;
    }

    try {
      setIsLoading(true);
      const uploadPath = `resumes/${formData.resume.name}`;
      const storageRef = ref(storage, uploadPath);

      // Téléchargement du fichier sur Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, formData.resume);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          setErrorMessage('An error occurred while uploading the file.');
          setIsLoading(false);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Appel à la fonction Firebase pour envoyer la candidature
            const functions = getFunctions(app);
            const sendCarriere = httpsCallable(functions, 'sendCarriere');

            const result = await sendCarriere({
              ...formData,
              resume: downloadURL,
            });

            if (result.data.success) {
              e.target.reset(); // Réinitialiser le formulaire
              setFormData({
                name: '',
                email: '',
                resume: null,
                coverLetter: '',
              });
              setMessage('Your application has been successfully submitted.');
            } else {
              setErrorMessage('Submission failed. Please try again.');
            }
          } catch (error) {
            setErrorMessage('An error occurred while sending the application.');
          } finally {
            setIsLoading(false);
          }
        }
      );
    } catch (error) {
      setErrorMessage('An error occurred during submission.');
      setIsLoading(false);
    }
  };

  return (
    <section className='bg-gray-100'>
      <div className='py-10 w-[95%] md:w-[50%] mx-auto'>
        <h1 className='text-3xl font-bold text-center mb-8'>Join Our Team</h1>
        <div className='bg-white shadow-md rounded-lg p-6'>
          <h2 className='text-2xl font-semibold mb-4'>Apply for job</h2>
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-4'>
            <div>
              <label className='block mb-2 text-gray-700'>Name</label>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Email</label>
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Resume</label>
              <input
                type='file'
                name='resume'
                onChange={handleInputChange}
                required
                className='w-full px-3 py-2 border rounded'
              />
            </div>
            <div>
              <label className='block mb-2 text-gray-700'>Cover Letter</label>
              <textarea
                rows={5}
                name='coverLetter'
                value={formData.coverLetter}
                onChange={handleInputChange}
                required
                className='resize-none w-full px-3 py-2 border rounded'
              />
            </div>
            <div className='space-x-2 flex items-center'>
              <span>{num1}</span>
              <span>+</span>
              <span>{num2}</span>
              <span>=</span>
              <input
                className='p-1 border-gray-300 border outline-none w-[70px]'
                type='number'
                min={0}
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
              />
              <span className='text-[1.2rem]'>?</span>
            </div>
            {errorMessage && <div className='text-red-500'>{errorMessage}</div>}
            {resultMessage && (
              <div className='text-red-600'>{resultMessage}</div>
            )}
            {message && <div className='text-lime-600'>{message}</div>}
            {isLoading && <div>Loading : {progress.toFixed(2)}%</div>}
            <div>
              <button
                type='submit'
                disabled={isLoading}
                className='bg-[#00075e] text-white py-2 px-4 rounded hover:bg-[#00054a]'
              >
                Submit Application
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default SendCarriere;
