import { useState } from 'react';
import NewCard from '../NewCard';
import Button from '../../utilities/Button';

const NewList = ({ docs, loading }) => {
  const [isHovered, setisHoved] = useState('');
  const [newsLength, setNewsLength] = useState(6);

  if (loading) {
    return (
      <div className='h-[16vh] flex items-center justify-center w-[95%] md:w-[80%] mx-auto'>
        <h3 className=' text-[2rem] text-[#474747]'>Laoding...</h3>
      </div>
    );
  }

  if (docs.length === 0) {
    return (
      <div className='flex flex-col items-center justify-center w-full  h-[30vh] bg-gray-100'>
        <div className='  text-center'>
          <h1 className='text-2xl font-semibold text-[#00075e] mb-4'>
            No News Available
          </h1>
          <p className='text-gray-600'>
            There are currently no news on the site.
          </p>
          <p className='text-gray-600'>
            News are in progress and will be available soon.
          </p>
        </div>
      </div>
    );
  }

  const newsLoading = docs.slice(0, newsLength);
  const isAllLoaded = newsLength >= docs.length;

  return (
    <div className=' w-[97%] md:w-[90%] lg:w-[80%] mx-auto py-5'>
      <div className='grid-cols-1 gap-8 grid md:grid-cols-2 xl:grid-cols-3'>
        {newsLoading.map((newsItem, index) => (
          <NewCard
            newsItem={newsItem}
            index={index}
            setisHoved={setisHoved}
            isHovered={isHovered}
          />
        ))}
      </div>
      <div className=' py-2 w-full flex justify-center items-center'>
        <Button
          clickHandler={() => setNewsLength(newsLength + 6)}
          disabled={isAllLoaded}
        >
          Load more
        </Button>
      </div>
    </div>
  );
};

export default NewList;
