import React from 'react';
import MissionHeader from '../components/Mission/MissionHeader';
import MissionFirstSection from '../components/Mission/MissionFirstSection';
import MissionSecondSection from '../components/Mission/MissionSecondSection';

const NotreMission = () => {
  return (
    <main>
      <MissionHeader />
      <MissionFirstSection />
      <MissionSecondSection />
    </main>
  );
};

export default NotreMission;
