import React from 'react';
import SelectionHeader from '../components/Selection/SelectionHeader';
import InstitutesAndDepartments from '../components/Selection/InstitutesAndDepartments';
import FooterContact from '../utilities/FooterContact';
const institutes = [
  {
    title: 'Site experience',
    description:
      "It is important to consider the site's experience in conducting clinical research, particularly in the relevant therapeutic area. ",
  },
  {
    title: 'Site personnel skills and abilities',
    description:
      'The skills and abilities of the site personnel involved in the trial are crucial for its successful execution.',
  },
  {
    title: 'Site staffing, resources, workload, and time commitments',
    description:
      'The availability of adequate staffing, resources, and time commitments are essential for the smooth conduct of the trial.',
  },
  {
    title: 'Access to the targeted population',
    description:
      ' Sites should have access to the targeted population that meets the required eligibility criteria for the trial.',
  },
  {
    title: 'Subject diversity requirements',
    description:
      'Sites should be able to recruit subjects from diverse backgrounds to ensure the generalizability of the trial results.',
  },
  {
    title: 'Technical facilities and equipment',
    description:
      'The availability of necessary technical facilities and equipment at the site is important for conducting the trial effectively.',
  },
  {
    title: 'Ethical review committee policies and procedures',
    description:
      'Sites should have robust ethical review committee policies and procedures in place to ensure the protection of subjects rights and welfare.',
  },
  {
    title: 'Institutional legal procedures',
    description:
      'Sites should adhere to institutional legal procedures to ensure compliance with regulatory requirements.',
  },
  {
    title: 'Site training and standard operating procedures',
    description:
      'Sites should have adequate training programs and standard operating procedures in place to ensure consistency and compliance with the trial protocol.',
  },
  {
    title: 'GCP compliance',
    description:
      'Sites should follow good clinical practice (GCP) guidelines to ensure the integrity and quality of the trial data.',
  },
  {
    title: 'Site source document and data collection procedures',
    description:
      ' Sites should have reliable procedures for documenting and collecting trial data accurately',
  },
  {
    title: 'Site security and storage facilities',
    description:
      'Sites should have secure storage facilities to protect trial-related documents and data.',
  },
  {
    title: 'Site selection decision-making process',
    description:
      'The site selection decision-making process should be transparent and well-documented to ensure accountability. ',
  },
  {
    title:
      'Role and accountability of CRA in managing site and subject enrolment',
    description:
      'Clinical research associates (CRAs) play a crucial role in managing site activities and subject enrollment, and their responsibilities should be clearly defined. Considering these factors can help ensure the successful conduct of clinical trials and the generation of reliable data for regulatory approval and patient care improvements. ',
  },
];
const Selection = () => {
  return (
    <main>
      <SelectionHeader />
      <InstitutesAndDepartments
        desc='Site selection for clinical trials is a critical aspect that requires
          careful consideration of various factors. Some of the key factors that
          should influence investigator site selection include'
        institutes={institutes}
      />
      <FooterContact />
    </main>
  );
};

export default Selection;
