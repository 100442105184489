import { Link } from 'react-router-dom';

const QueFaisonsNous = () => {
  return (
    <section className='bg-[#eef5ff] py-12'>
      <div>
        <div className=' flex-col flex items-center justify-center'>
          <h1 className=' text-[#00075e] leading-[1.2] text-[1.6rem] md:text-[1.8rem] my-2 font-semibold '>
            What Do We Do?
          </h1>
          <p className=' text-center'>
            Clinical research services for superior quality.
          </p>
        </div>
        <div className=' py-5 lg:space-x-5 w-[95%] md:w-[90%] xl:w-[80%] mx-auto flex flex-col lg:flex-row'>
          <div className='flex flex-col md:space-y-6'>
            <Link
              to={'/vision'}
              className=' flex-col sm:flex-row p-5 sm:space-x-2 justify-between rounded-xl sm:rounded-full items-center bg-[#fff] flex'
            >
              <div className=' flex-1'>
                <div className=' flex-col sm:flex-row items-center flex space-x-2'>
                  <div className='text-center w-[50px] flex  h-[50px]  rounded-full items-center justify-center border-[1px] border-[#00075e]'>
                    <span className='  '>1</span>
                  </div>

                  <span className='flex flex-1 font-semibold text-[1.6rem]'>
                    Clinical Trials
                  </span>
                </div>
                <p className=' text-center md:text-start'>
                  Leading CRO providing comprehensive clinical trial support
                  across continents.
                </p>
              </div>
              <span className=' w-[50px] h-[50px] md:w-[80px]  md:h-[80px] rounded-full flex items-center justify-center bg-[#00075E]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className=' h-6 w-6 md:h-8 md:w-8 text-white'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                  />
                </svg>
              </span>
            </Link>
            <Link
              className=' flex-col sm:flex-row p-5 sm:space-x-2 justify-between rounded-xl sm:rounded-full items-center bg-[#fff] flex'
              to={'/feasability'}
            >
              <div className=' flex-1'>
                <div className=' flex-col sm:flex-row items-center flex space-x-2'>
                  <div className='text-center w-[50px] flex  h-[50px]  rounded-full items-center justify-center border-[1px] border-[#00075e]'>
                    <span className='  '>2</span>
                  </div>

                  <span className='flex flex-1 font-semibold text-[1.6rem]'>
                    Feasibility
                  </span>
                </div>
                <p className=' text-center md:text-start'>
                  Essential Pre-Trial Support for Clinical Research Success
                </p>
              </div>
              <span className=' w-[50px] h-[50px] md:w-[80px]  md:h-[80px] rounded-full flex items-center justify-center bg-[#00075E]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className=' h-6 w-6 md:h-8 md:w-8 text-white'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                  />
                </svg>
              </span>
            </Link>
          </div>
          <div className=' mt-5 lg:mt-16 flex flex-col space-y-6'>
            <Link
              className=' flex-col sm:flex-row p-5 sm:space-x-2 justify-between rounded-xl sm:rounded-full items-center bg-[#fff] flex'
              to={'/approch'}
            >
              <div className=' flex-1'>
                <div className=' flex-col sm:flex-row items-center flex space-x-2'>
                  <div className='text-center w-[50px] flex  h-[50px]  rounded-full items-center justify-center border-[1px] border-[#00075e]'>
                    <span className='  '>3</span>
                  </div>

                  <span className=' flex flex-1 font-semibold text-[1.6rem]'>
                    Approch
                  </span>
                </div>
                <p className=' text-center md:text-start'>
                  Scientific Council Enhancing Excellence in Clinical Research
                  Practices
                </p>
              </div>
              <span className=' w-[50px] h-[50px] md:w-[80px]  md:h-[80px] rounded-full flex items-center justify-center bg-[#00075E]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className=' h-6 w-6 md:h-8 md:w-8 text-white'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                  />
                </svg>
              </span>
            </Link>
            <Link
              className=' flex-col sm:flex-row p-5 sm:space-x-2 justify-between rounded-xl sm:rounded-full items-center bg-[#fff] flex'
              to={'/quality-assurance'}
            >
              <div className='flex-1'>
                <div className=' flex-col sm:flex-row items-center flex space-x-2'>
                  <div className='text-center w-[50px] flex  h-[50px]  rounded-full items-center justify-center border-[1px] border-[#00075e]'>
                    <span className='  '>4</span>
                  </div>

                  <span className='text-center md:text-start flex flex-1 font-semibold text-[1.6rem]'>
                    Quality assurance
                  </span>
                </div>
                <p className=' text-center md:text-start'>
                  Ensuring Compliance and Integrity in Clinical Research
                  Processes
                </p>
              </div>
              <span className=' w-[50px] h-[50px] md:w-[80px]  md:h-[80px] rounded-full flex items-center justify-center bg-[#00075E]'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={1.5}
                  stroke='currentColor'
                  className=' h-6 w-6 md:h-8 md:w-8 text-white'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                  />
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QueFaisonsNous;
