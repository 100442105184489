import img from '../public/images/banner_9.png';

const FooterContact = () => {
  return (
    <section
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.70), rgba(0, 16, 96, 0.70)),url(${img})`,
        // height: '30vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        color: '#FFFFFF',
      }}
      className=' py-10 '
    >
      <div className=' flex flex-col md:grid-cols-2 w-[95%] lg:w-[90%] xl:w-[80%] 2xl:w-[60%] mx-auto'>
        <h2 className=' font-semibold text-[1.4rem] text-center  md:text-[1.8rem]'>
          More information about
        </h2>

        <div className='  w-full flex items-center py-10  flex-col space-y-2'>
          <div className='md:border-l-[2px] md:border-[#fff]  md:pl-5 space-x-2 flex items-start'>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth={1.5}
                stroke='currentColor'
                className='size-6'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                />
              </svg>
            </span>
            <div className=' space-x-3 flex'>
              <span className=' '>Email:</span>
              <span> sales@keindeya.com</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterContact;
