import img from '../../public/images/banner.png';

const MissionSecondSection = () => {
  return (
    <div
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.50), rgba(0, 16, 96, 0.50)),url(${img})`,
        height: '40vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className='w-[97%] md:w-[90%] lg:w-[50%] flex-col mx-auto flex items-center h-full justify-center'>
        <h2 className=' text-[1.6rem] text-[#fff] font-semibold'>
          Project management
        </h2>
        <p className=' text-[#fff] text-center'>
          Regulatory process optimization, management monitoring, etc. Our offer
          is flexible and we can also work on all of the above or simply on one
          or several modules.
        </p>
      </div>
    </div>
  );
};

export default MissionSecondSection;
