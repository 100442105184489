import { useEffect, useRef, useState } from 'react';
import { db } from '../firebase/config';
import {
  collection,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

export const useCollection = (
  collectionDoc,
  _country,
  _company,
  _title,
  _sellerId,
  _userId,
  _isSeller
) => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    let ref = collection(db, collectionDoc);

    if (_title && _title.trim() !== '') {
      ref = query(
        ref,
        where('userName', '>=', _title.trim().toLowerCase()),
        where('userName', '<', _title.trim().toLowerCase() + '\uf8ff')
      );
    }

    if (_title) {
      ref = query(ref, orderBy('userName', _title));
    }

    if (_company) {
      ref = query(ref, where('companyType', '==', _company));
    }
    if (_country) {
      ref = query(ref, where('countries', '==', _country));
    }

    if (_sellerId) {
      ref = query(ref, where('sellerId', '==', _sellerId));
    }

    if (_userId) {
      ref = query(ref, where('uid', '==', _userId));
    }
    if (_isSeller) {
      ref = query(ref, where('isSeller', '==', true));
    }
    const unsub = onSnapshot(
      ref,
      (snapshop) => {
        let results = [];

        snapshop.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });

        setLoading(false);
        setDocuments(results);
      },
      (err) => {
        console.log(err);
        setLoading(false);
        setError('Impossible de recurer les données');
      }
    );

    return () => unsub();
  }, [
    collectionDoc,
    _country,
    _company,
    _title,
    _sellerId,
    _userId,
    _isSeller,
  ]);

  return { documents, loading, error };
};
