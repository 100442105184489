export const formatDate = (timestamp) => {
  const date = new Date(timestamp); // Firebase timestamp is in milliseconds
  const day = String(date.getDate()).padStart(2, '0'); // Ajouter un zéro si nécessaire

  const monthNames = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  const month = monthNames[date.getMonth()]; // Obtenir le nom du mois
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};
