import NewHeader from '../components/news/NewHeader';
import NewList from '../components/news/NewList';
import { useCollection } from '../hooks/useCollection';

const News = () => {
  let docs = [];

  const { documents, loading, error } = useCollection('blogs');
  docs = documents;

  docs = docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <main>
      <NewHeader />
      <NewList loading={loading} docs={docs} />
    </main>
  );
};

export default News;
