import React from 'react';

const ContactForm = () => {
  return (
    <section className=' py-10 bg-[#00075e]'>
      <div className=' w-[95%] lg:w-[50%] mx-auto'>
        <div className='mb-8'>
          <h3 className=' mb-1 text-[1.8rem] font-semibold  text-center  text-[#fff]'>
            Get in touch
          </h3>
          <p className=' text-center text-[#fff]'>
            Get in touch with us for any inquiries, questions, or feedback about
            our clinical trial services.
          </p>
        </div>
        <form>
          <div className='mb-4 grid gap-5 grid-cols-2'>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-[#00075e] leading-tight focus:outline-none focus:shadow-outline'
              id='name'
              type='text'
              placeholder='Your name'
            />
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-[#00075e] leading-tight focus:outline-none focus:shadow-outline'
              id='name'
              type='text'
              placeholder='Your first name'
            />
          </div>
          <div className=' gap-5 grid grid-cols-2 mb-4'>
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-[#00075e] leading-tight focus:outline-none focus:shadow-outline'
              id='email'
              type='email'
              placeholder='Your email'
            />
            <input
              className='shadow appearance-none border rounded w-full py-2 px-3 text-[#00075e] leading-tight focus:outline-none focus:shadow-outline'
              id='subject'
              type='text'
              placeholder='Subject'
            />
          </div>

          <div className='mb-4'>
            <textarea
              className='shadow resize-none appearance-none border rounded w-full py-2 px-3 text-[#00075e] leading-tight focus:outline-none focus:shadow-outline'
              id='message'
              placeholder='Your message'
              rows='5'
            ></textarea>
          </div>
          <div className='text-center'>
            <button
              className='bg-[#fff]  text-[#00075e] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
              type='button'
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
