const Button = ({ children, disabled, clickHandler }) => {
  return (
    <button
      onClick={clickHandler}
      className={`border-[1px] px-6 py-2 rounded-[50px] bg-transparent border-[#00075e] text-[#00075e] ${
        disabled ? 'cursor-not-allowed opacity-50' : ''
      }`}
    >
      {children}
    </button>
  );
};

export default Button;
