import organazation from './../../public/images/organization.svg';
import clinical from './../../public/images/clinical.svg';
import diagram from './../../public/images/diagram-project-1-svgrepo-com.svg';
import expert from './../../public/images/expert.svg';

const VisionFirstSection = () => {
  return (
    <div className='bg-[#F3F7FA] py-10'>
      <div className=' w-[97%] md:w-[90%] xl:w-[80%] mx-auto'>
        <h2 className=' text-[2rem] text-[#00075e] font-semibold'>
          Keindeya's Vision for{' '}
          <span className=' text-[#0264b0]'>Clinical Trials</span>{' '}
        </h2>
        <p className=' my-2 text-[1.1rem]'>
          Keindeya pioneers clinical trials in Europe and Africa, ensuring
          quality through strong local presence and regulatory expertise.
        </p>
        <div
          style={{
            gridAutoRows: 'auto',
            gridAutoFlow: 'row',
            gridTemplateRows: 'auto',
          }}
          className='grid grid-cols-1 md:grid-cols-2  auto-rows-fr gap-6'
        >
          <div className='bg-[#ffff]  p-6 rounded-lg shadow-lg'>
            <div className='bg-[#d5f7f8] inline-block p-3 rounded-full'>
              <img
                src={organazation}
                className=' h-8 w-8'
                alt='organazation icon'
              />
            </div>
            <div>
              <h3 className='text-xl font-bold mb-2'>
                Clinical Trials Organization
              </h3>
              <p>
                Our team wishes to organize the realization of clinical trials
                in Europe and Africa for prestigious Sponsors while
                collaborating with experts of the Scientific Community.
              </p>
            </div>
          </div>

          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <div className='bg-[#d5f7f8] inline-block p-3 rounded-full'>
              <img src={expert} className=' h-8 w-8' alt='organazation icon' />
            </div>
            <div>
              <h3 className='text-xl font-bold mb-2'>Expert Collaboration</h3>
              <p>
                Keindeya wants to make available to Sponsors, his strong
                knowledge, relayed by a network of trained Investigators in Good
                Clinical Practice, essential for the realization of clinical
                trials quality.
              </p>
            </div>
          </div>

          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <div className='bg-[#d5f7f8] inline-block p-3 rounded-full'>
              <img src={diagram} className=' h-8 w-8' alt='organazation icon' />
            </div>
            <div>
              <h3 className='text-xl font-bold mb-2'>Regulatory Process</h3>
              <p>
                The perfect control of the regulatory process, close relations
                with regulatory authorities, investigators and centers, site
                qualification are factors of success and quality.
              </p>
            </div>
          </div>

          <div className='bg-white p-6 rounded-lg shadow-lg'>
            <div className='bg-[#d5f7f8] inline-block p-3 rounded-full'>
              <img
                src={clinical}
                className=' h-8 w-8'
                alt='organazation icon'
              />
            </div>
            <div>
              <h3 className='text-xl font-bold mb-2'>Clinical Trials Phases</h3>
              <p>
                We carry out phase I to IV clinical trials on different
                therapeutic areas on behalf of ours clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionFirstSection;
