const ContentElts = ({ style, title, description, svg }) => {
  return (
    <div
      style={style}
      className='p-2 md:p-20 sm:space-x-6 items-start  flex-col flex md:flex-row my-6'
    >
      <div>
        <div className=' rounded-full w-[150px] p-4 h-[150px] flex items-center   bg-[#a4cafc]'>
          <img src={svg} className=' h-24 w-24' alt='' />
        </div>
      </div>
      <div className=' text-[#fff] '>
        <h3 className=' text-[2rem]'>{title}: </h3>
        <p className=' text-[1.4rem]'>{description}</p>
      </div>
    </div>
  );
};

export default ContentElts;
