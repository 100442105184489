import { Link } from 'react-router-dom';
import svg from '../../src/public/images/book.svg';
import svg_2 from '../../src/public/images/virus.svg';
import svg_white from '../../src/public/images/book-white.svg';
import svg_2_white from '../../src/public/images/virus-white.svg';

const NewCard = ({ newsItem, setisHoved, isHovered, index }) => {
  return (
    <Link
      onMouseEnter={() => setisHoved(index)}
      onMouseLeave={() => setisHoved('')}
      to={`/blog/${newsItem.id}`}
      className='new transition-all duration-700 border-[1px] border-[#00065e72] rounded-md p-4'
    >
      <div className=' rounded-md  h-[250px] md:h-[300px] w-full '>
        <img
          src={newsItem.imageUrl}
          alt='images'
          className=' rounded-md h-full w-full object-cover'
        />
      </div>
      <div className=' py-2 flex space-x-10'>
        <div className=' flex items-center  space-x-1'>
          <img
            src={isHovered === index ? svg_white : svg}
            alt='svg'
            className=' h-5 w-5 object-cover'
          />
          <span className=' new-publisher text-[#00075e] hover:text-[#fff] font-semibold text-[1rem]'>
            Dr.Kaba
          </span>
        </div>
        <div className=' flex items-center  space-x-1'>
          <img
            src={isHovered === index ? svg_2_white : svg_2}
            alt='svg'
            className=' h-5 w-5 object-cover'
          />
          <span className=' new-categories text-[#00075e] font-semibold text-[1rem]'>
            Ebola
          </span>
        </div>
      </div>
      <div>
        <h2 className=' new-title'>
          {newsItem.title.length > 50
            ? newsItem.title.substring(0, 50) + '...'
            : newsItem.title}
        </h2>

        <div
          className='new-description text-[0.9rem]'
          dangerouslySetInnerHTML={{
            __html:
              newsItem.description.length > 100
                ? String(newsItem.description.substring(0, 100)) + '...'
                : newsItem.description,
          }}
        />
        <div className=' py-1 flex items-center '>
          <button className=' new-btn text-[#0ab8cf] border-none'>
            Read more
          </button>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke-width='1.5'
              stroke='currentColor'
              class='size-6 text-[#0ab8cf] new-read-svg'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
              />
            </svg>
          </span>
        </div>
      </div>
    </Link>
  );
};

export default NewCard;
