import React from 'react';
import Title from '../home/Title';
import Subtitle from '../home/Subtitle';
import ContentElts from './ContentElts';
import img from './../../public/images/banner_7.png';
import svg_1 from './../../public/images/approch_1.svg';
import svg_2 from './../../public/images/approch_2.svg';

const ApprochContent = () => {
  const style = {
    background: `linear-gradient(rgba(0, 16, 96, 1), rgba(0, 16, 96, 1)),url(${img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 15,
  };

  const style2 = {
    background: `linear-gradient(rgba(0, 16, 96, 0.680), rgba(0, 16, 96, 0.680)),url(${img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: 15,
  };

  return (
    <section className=' py-8'>
      <div className='w-[97%] md:w-[90%] lg:w-[70%] mx-auto'>
        <Title text='Evaluation of the feasibility' />
        <Subtitle
          spanText='Clinical Research'
          subtile='Scientific Committee Enhancing Quality in '
        />
        <ContentElts
          svg={svg_2}
          style={style}
          title='Role'
          description='The Scientific Council at Keindeya plays a crucial role in supporting the 
operational teams in conducting clinical trials. They provide valuable assistance 
in various aspects of the research process and help in selecting the best 
investigators for the studies. By bringing together experts in the field, the 
council ensures that the trials are conducted with precision and adhere to high quality standards'
        />
        <ContentElts
          svg={svg_1}
          style={style2}
          title='Composition'
          description="The Scientific Council comprises a diverse group of experts with 
vast knowledge and experience in clinical trials. Their collective expertise adds 
significant value to Keindeya's Contract Research Organization (CRO), 
enhancing the quality and reliability of the trials conducted. The council's ability 
to mobilize top specialists and researchers contributes to the success of the 
studies undertaken by Keindeya. By leveraging the insights and guidance 
provided by the Scientific Council, Keindeya is able to enhance the 
effectiveness and credibility of its clinical research endeavours, ultimately 
benefiting both the company and the broader scientific community"
        />
      </div>
    </section>
  );
};

export default ApprochContent;
