const InstitutesAndDepartments = ({ desc, institutes }) => {
  return (
    <section className='flex flex-col md:flex-row p-2 md:p-10 bg-white'>
      <div className='md:w-1/3 p-4'>
        <h1 className='text-2xl md:text-3xl font-bold mb-4'>
          Institutes & Departments
        </h1>
        <p className='mb-4'>{desc}</p>
      </div>
      <div className='md:w-2/3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 p-2 md:p-4'>
        {institutes.map((institute, index) => (
          <div key={index} className='bg-[#f8f8f9] p-4 rounded-lg institutes'>
            <h2 className='text-xl font-semibold mb-2'>{institute.title}</h2>
            <p>{institute.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default InstitutesAndDepartments;
