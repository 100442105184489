import { db } from '../firebase/config';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useEffect, useReducer, useState } from 'react';

const initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const docReducer = (state, action) => {
  switch (action.type) {
    case 'IS_PENDING':
      return { ...state, isPending: true, error: null };
    case 'ADD_DOC':
      return {
        document: action.payload,
        isPending: false,
        error: null,
        success: true,
      };
    case 'UPDATED_DOC':
      return {
        document: action.payload,
        isPending: false,
        error: null,
        success: true,
      };
    case 'ERROR':
      return {
        document: null,
        isPending: false,
        error: action.payload,
        success: false,
      };
    case 'REMOVE_DOC':
      return {
        document: null,
        isPending: false,
        error: action.payload,
        success: true,
      };
    default:
      return state;
  }
};

export const useDocument = (document, id) => {
  const [docResponse, dispatch] = useReducer(docReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const docRef = doc(db, document, id);

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  useEffect(() => {
    const getDocument = (id) => {
      dispatch({ type: 'IS_PENDING' });

      onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            dispatchIfNotCancelled({
              type: 'ADD_DOC',
              payload: { id, ...data },
            });
          } else {
            dispatchIfNotCancelled({
              type: 'ERROR',
              payload: 'Document not found',
            });
          }
        },
        (err) => {
          dispatchIfNotCancelled({ type: 'ERROR', payload: err.message });
        }
      );
    };
    getDocument(id);
    return () => getDocument(id);
  }, [id]);

  useEffect(() => {
    setIsCancelled(false);
    return () => setIsCancelled(true);
  }, []);

  return {
    docResponse,
  };
};
