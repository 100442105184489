import PagesHeader from '../components/PagesHeader';
import InstitutesAndDepartments from '../components/Selection/InstitutesAndDepartments';
import img from '../public/images/banner_7.png';
import FooterContact from '../utilities/FooterContact';

const institutes = [
  {
    title: ' Audits',
    description:
      'Regular audits are conducted on investigator sites, Contract Research Organizations (CROs), and Pharmacovigilance (PV) Systems to ensure compliance with regulatory standards and best practices. ',
  },
  {
    title: 'Quality Control',
    description:
      'Document control is a crucial part of maintaining data integrity and regulatory compliance. Ensuring the accuracy of documents submitted to relevant authorities is essential for maintaining trust and credibility.',
  },
  {
    title: 'Drafting and Revision of Procedures',
    description:
      'Standard Operating Procedures (SOPs) are the backbone of quality assurance. Drafting and revising procedures to align with regulatory requirements and industry best practices is essential for consistency and compliance. ',
  },
  {
    title: 'Training',
    description:
      'Providing ongoing training to the operations team on procedures and Good Clinical Practice (GCP) guidelines ensures that all staff are equipped with the necessary skills and knowledge to perform their roles effectively. ',
  },
  {
    title: 'Quality Support',
    description:
      'Offering continuous support to the operations team in maintaining quality standards, addressing concerns, and implementing corrective actions is essential for fostering a culture of quality and compliance within the organization. ',
  },
];

const QualityAssurance = () => {
  return (
    <main>
      <PagesHeader title={'Quality assurance'} img={img} />
      <InstitutesAndDepartments
        desc='A critical aspect of ensuring the reliability and accuracy of processes within the 
pharmaceutical industry. Some key elements of quality assurance include'
        institutes={institutes}
      />
      <FooterContact />
    </main>
  );
};

export default QualityAssurance;
