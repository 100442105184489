import React from 'react';

const Title = ({ text }) => {
  return (
    <h1 className=' text-[1.1rem] uppercase font-semibold text-[#00075e]'>
      {text}
    </h1>
  );
};

export default Title;
