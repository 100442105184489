const Copyright = () => {
  return (
    <footer className=' text-gray-300 py-4'>
      <div className='container mx-auto text-center'>
        <p>
          &copy;2016-{new Date().getFullYear()} keindeya. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Copyright;
