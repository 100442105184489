import ApplicationDetails from '../components/Career/ApplicationDetails';
import SendCarriere from '../components/Career/SendCarriere';
import PagesHeader from '../components/PagesHeader';
import img from '../public/images/banner_8.png';

const Career = () => {
  return (
    <main>
      <PagesHeader title={'Career'} img={img} />
      <SendCarriere />
      <ApplicationDetails />
    </main>
  );
};

export default Career;
