import PagesHeader from '../components/PagesHeader';
import PublicationContent from '../components/Publication/PublicationContent';
import { useCollection } from '../hooks/useCollection';
import img from '../public/images/banner_7.png';

const Publication = () => {
  let docs = [];

  const { documents, loading, error } = useCollection('publications');
  docs = documents;

  docs = docs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  return (
    <main>
      <PagesHeader title={'Publication'} img={img} />
      <PublicationContent loading={loading} docs={docs} />
    </main>
  );
};

export default Publication;
