import React from 'react';
import img from '../../public/images/banner.png';

const MissionHeader = () => {
  return (
    <div
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.750), rgba(0, 16, 96, 0.750)),url(${img})`,
        height: '40vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='w-[97%] md:w-[90%] xl:w-[50%] flex-col mx-auto flex items-center h-full justify-center'>
        <h1 className=' text-[2.5rem] text-[#fff] font-semibold'>
          Our Mission
        </h1>
        <p className=' text-[#fff] text-center'>
          To conduct Phase I to Phase IV clinical trials for sponsors, we
          provide the following services in compliance with local regulatory
          requirements.
        </p>
      </div>
    </div>
  );
};

export default MissionHeader;
