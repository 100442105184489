import React from 'react';
import { Link } from 'react-router-dom';
import Copyright from './Copyright';

const Footer = () => {
  return (
    <footer className=' bg-[#00075e] py-10'>
      <div className='border-b-2'>
        <div className=' text-white w-[90%]  pb-7 mx-auto'>
          <ul className=' gap-3 xl:gap-x-20 grid md:grid-cols-2 lg:grid-cols-3'>
            <li>
              <h2 className=' inline-block border-l-[5px] border-l-[#fff] pl-2 text-[1.2rem] capitalize font-bold'>
                About us
              </h2>
              <ul className='   mt-2 space-y-[1px] ml-4 grid grid-cols-2 capitalize'>
                <li className='  '>
                  <Link
                    className=' text-[#fff] transition-all duration-300  block w-full '
                    to='/vision'
                  >
                    vision
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/mission'
                  >
                    mission
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/approche'
                  >
                    approch
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/publication'
                  >
                    publication
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className=' inline-block border-l-[5px] border-l-[#fff] pl-2 text-[1.2rem] capitalize font-bold'>
                Services
              </h2>
              <ul className=' mt-2 space-y-[1px] ml-4 grid grid-cols-2 capitalize'>
                <li className='  '>
                  <Link
                    className=' text-[#fff] transition-all duration-300  block w-full '
                    to='/feasability'
                  >
                    feasability
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/selection'
                  >
                    selection
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/monitoring'
                  >
                    monitoring
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/quality-assurance'
                  >
                    quality assurance
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <h2 className=' inline-block border-l-[5px] border-l-[#fff] pl-2 text-[1.2rem] capitalize font-bold'>
                Liens utiles
              </h2>
              <ul className=' mt-2 space-y-[1px] ml-4 grid grid-cols-2 capitalize'>
                <li className='  '>
                  <Link
                    className=' text-[#fff] transition-all duration-300  block w-full '
                    to='/news'
                  >
                    News
                  </Link>
                </li>

                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/contact'
                  >
                    Contact
                  </Link>
                </li>
                <li className='  '>
                  <Link
                    className=' transition-all duration-300   block w-full '
                    to='/career'
                  >
                    Career
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <Copyright />
    </footer>
  );
};

export default Footer;
