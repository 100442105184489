import img from '../../public/images/banner_7.png';

const FeasabilityFooter = () => {
  return (
    <section
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.78), rgba(0, 16, 96, 0.78)),url(${img})`,
        // height: '40vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
    >
      <div className=' py-10 w-[97%] md:w-[90%] lg:w-[50%] flex-col mx-auto flex items-center h-full justify-center'>
        <h2 className=' text-center text-[1.2rem] md:text-[1.6rem] text-[#fff] font-semibold'>
          Specializing in high-quality{' '}
          <span className=' text-[#11b2ed]'>clinical trials</span> across
          various therapeutic areas, ensuring success.
        </h2>
      </div>
    </section>
  );
};

export default FeasabilityFooter;
