import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../public/images/logo.png';
import Button from './Button';

const MobileMenu = ({ openModal, setOpenModal }) => {
  const [openElts, setOpenElts] = useState();

  return (
    <div
      onClick={() => setOpenModal(false)}
      className={`fixed z-[100] transition-all duration-500 bg-[#00000068] w-full -top-0 ${
        openModal ? 'translate-x-0' : '-translate-x-[70rem]'
      }   h-[120vh] lg:hidden`}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className=' pt-2 z-[999] bg-[#00075e] relative w-[70%] h-[120vh] '
      >
        <ul className=' px-2 text-[#fff] py-7 flex flex-col  font-semibold text-[0.9rem] uppercase  space-y-6'>
          <li>
            <Link className=' text-[#fff]' to='/'>
              Home
            </Link>
          </li>
          <li className='  '>
            <button
              onClick={() => setOpenElts(openElts === 1 ? '' : 1)}
              className=' uppercase w-full  space-x-4 flex cursor-pointer'
            >
              <span className='block uppercase'>About Us</span>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className={`size-6 h-4 w-4 ${
                    openElts === 1 ? 'rotate-180' : ''
                  }`}
                >
                  <path
                    fillRule='evenodd'
                    d='M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
            </button>
            <div
              className={`${
                openElts === 1 ? 'block' : 'hidden'
              } ml-5 w-[10rem]  z-50`}
            >
              <ul className=' text-[0.75rem] w-full pt-4 font-[600]      flex flex-col'>
                <li className=' border-b-[1px]  border-t-[3px] border-t-[#003f97]'>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/vision'
                  >
                    vision
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/mission'
                  >
                    mission
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/approch'
                  >
                    approch
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/publication'
                  >
                    publication
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className=''>
            <button
              onClick={() => setOpenElts(openElts === 2 ? '' : 2)}
              className=' uppercase w-full  space-x-4 flex cursor-pointer'
            >
              <span>Services</span>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  className={` h-4 w-4 ${openElts === 2 ? 'rotate-180' : ''}`}
                >
                  <path
                    fillRule='evenodd'
                    d='M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z'
                    clipRule='evenodd'
                  />
                </svg>
              </span>
            </button>
            <div
              className={`${
                openElts === 2 ? 'block' : 'hidden'
              } ml-5 w-[10rem]  z-50`}
            >
              <ul className='text-[0.75rem] w-full pt-4 font-[600]      flex flex-col'>
                <li className=' border-b-[1px]  border-t-[3px] border-t-[#003f97]'>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/feasability'
                  >
                    Feasibility
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/selection'
                  >
                    selection
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/monitoring'
                  >
                    monitoring
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    onClick={() => setOpenModal(false)}
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/quality-assurance'
                  >
                    quality assurance
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link onClick={() => setOpenModal(false)} to='/career'>
              Career
            </Link>
          </li>
          <li>
            <Link onClick={() => setOpenModal(false)} to='/news'>
              News
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const Header = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <header>
      <MobileMenu openModal={openModal} setOpenModal={setOpenModal} />
      <div className=' relative z-[10] py-2 flex items-center justify-between w-[90%] mx-auto'>
        <div>
          <Link className=' flex flex-col' to={'/'}>
            <div className=' flex items-end space-x-1'>
              <div className=' h-12 '>
                <img
                  src={logo}
                  alt='image'
                  className='  mt-4 md:mt-0 h-8 md:h-12 object-cover'
                />
              </div>
              <h3 className=' text-[1.2rem] md:text-[2rem] font-extrabold text-[#00075e]'>
                Keindeya
              </h3>
            </div>
            <span className=' text-[0.6rem] md:text-[0.7rem] logo-desc'>
              A CRO for Africa & Europe
            </span>
          </Link>
        </div>
        <div className=' block lg:hidden'>
          <button onClick={() => setOpenModal(true)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              fill='currentColor'
              class='size-6 h-6 w-6'
            >
              <path
                fill-rule='evenodd'
                d='M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z'
                clip-rule='evenodd'
              />
            </svg>
          </button>
        </div>
        <ul className=' hidden  font-semibold text-[0.9rem] uppercase lg:flex items-end space-x-10'>
          <li>
            <Link to='/'>Home</Link>
          </li>
          <li className='menu_item relative'>
            <button className=' uppercase cursor-pointer'>About Us</button>
            <div className=' absolute w-[10rem]  z-50  '>
              <ul className='sub_menu text-[0.75rem] w-full pt-4 font-[600]  bg-white    flex flex-col'>
                <li className=' border-b-[1px]  border-t-[3px] border-t-[#003f97]'>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/vision'
                  >
                    vision
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/mission'
                  >
                    mission
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/approch'
                  >
                    approch
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/publication'
                  >
                    publication
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className='menu_item relative'>
            <div className=' cursor-pointer'>Services</div>
            <div className=' absolute w-[10rem]  z-50  '>
              <ul className='sub_menu text-[0.75rem] w-full pt-4 font-[600]  bg-white    flex flex-col'>
                <li className=' border-b-[1px]  border-t-[3px] border-t-[#003f97]'>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/feasability'
                  >
                    Feasibility
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/selection'
                  >
                    selection
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/monitoring'
                  >
                    monitoring
                  </Link>
                </li>
                <li className=' border-b-[1px] '>
                  <Link
                    className=' transition-all duration-300 py-2 px-3 block w-full hover:text-[#fff] border-b-[#00065e62] hover:bg-[#00075e]'
                    to='/quality-assurance'
                  >
                    quality assurance
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <Link to='/career'>Career</Link>
          </li>
          <li>
            <Link to='/news'>News</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
