import img from '../../public/images/img_1.png';

const VisionHeader = () => {
  return (
    <div className=' bg-[#00075e] '>
      <div className='w-[97%] md:w-[90%] xl:w-[80%] py-10 grid grid-cols-1 md:grid-cols-2 gap-6  mx-auto'>
        <div>
          <img
            src={img}
            className=' h-[500px] md:h-[600px] w-full object-cover rounded-tl-full'
            alt='images'
          />
        </div>
        <div className='text-[#fff]'>
          <h1 className='  font-semibold uppercase'>Our Vision</h1>
          <h2 className=' font-medium text-[2rem] leading-[1.2] py-5'>
            Bridging Africa and Europe for successful{' '}
            <span className=' text-[#0264b0]'> clinical research</span>.
          </h2>
          <div>
            <p className=' text-justify'>
              Keindeya is positioning itself as a pioneering Contract Research
              Organization (CRO) with a unique focus on organizing clinical
              trials within a Medical Scientific community that bridges Africa
              and Europe. By leveraging a strong local presence across both
              continents, Keindeya aims to facilitate the execution of clinical
              trials for esteemed Sponsors, while fostering collaboration with
              experts in the Scientific Community. One of Keindeya's key value
              propositions is its ability to provide Sponsors with extensive
              knowledge and expertise, which is disseminated through a network
              of trained Investigators well-versed in Good Clinical Practice
              (GCP). This ensures the quality and success of clinical trials
              conducted by the organization. Moreover, Keindeya places great
              emphasis on achieving impeccable regulatory compliance by
              maintaining close relationships with regulatory authorities,
              investigators, and research centers. The company's meticulous
              approach to site qualification and its proficiency in navigating
              the regulatory process are crucial elements that contribute to the
              high quality of its clinical trials. Keindeya's scope of services
              covers clinical trials spanning phases I to IV across various
              therapeutic areas on behalf of its clients. By offering
              comprehensive support and specialized expertise, Keindeya aims to
              be a trusted partner for Sponsors seeking efficient and effective
              clinical trial services in both Africa and Europe.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionHeader;
