import Subtitle from '../home/Subtitle';
import Title from '../home/Title';

const FeasabilityContent = () => {
  return (
    <section>
      <div className=' py-8 w-[97%] md:w-[90%] lg:w-[80%]  mx-auto'>
        <Title text='Pre-trial Visit' />
        <Subtitle
          spanText='Clinical Trial Preparation'
          subtile='Comprehensive Support for '
        />
        <div className='bg-gray-100 py-2 md:py-8'>
          <div className=' mx-auto px-2 md:px-8'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
              <div className='bg-white p-6 rounded-lg shadow-lg'>
                <h2 className='text-2xl font-bold text-gray-800'>
                  Site selection
                </h2>
                <p className='text-gray-600 mt-4'>
                  The process of selecting appropriate sites for conducting
                  clinical trials based on criteria such as patient population,
                  infrastructure, and previous experience. Pre-trial Visit: A
                  crucial step before the start of a clinical trial where
                  sponsors and investigators assess the site's capabilities,
                  resources, and readiness to conduct the study.
                </p>
              </div>

              <div className='bg-white  p-6 rounded-lg shadow-lg'>
                <h2 className='text-2xl font-bold text-gray-800'>
                  Estimation of potential patient’s recruitment
                </h2>
                <p className='text-gray-600 mt-4'>
                  Predicting the number of eligible patients that can be
                  enrolled in the clinical trial based on factors like disease
                  prevalence, site location, and recruitment strategies.
                </p>
              </div>
              <div className='bg-white p-6  rounded-lg shadow-lg'>
                <h2 className='text-2xl font-bold text-gray-800'>
                  Initiation, Interim monitoring, and Close-out Visits
                </h2>
                <p className='text-gray-600 mt-4'>
                  These visits involve initiating the trial, monitoring progress
                  at regular intervals, and finalizing activities once the trial
                  is complete, respectively.
                </p>
              </div>
              <div className='bg-white p-6 rounded-lg shadow-lg'>
                <h2 className='text-2xl font-bold text-gray-800'>
                  Personnel training, mentoring, and guidance
                </h2>
                <p className='text-gray-600 mt-4'>
                  Providing necessary training to site staff, mentoring them
                  throughout the trial, and offering guidance on protocol
                  adherence and data collection.
                </p>
              </div>
              <div className='bg-white p-6 rounded-lg shadow-lg'>
                <h2 className='text-2xl font-bold text-gray-800'>
                  Assessing protocol compliance and data integrity
                </h2>
                <p className='text-gray-600 mt-4'>
                  Ensuring that the trial is conducted according to the study
                  protocol, monitoring data quality, and maintaining the
                  integrity of study data throughout the trial duration. By
                  incorporating these key elements into your content, you can
                  provide a comprehensive overview of the clinical trial process
                  to your readers and help them understand the various stages
                  involved in conducting successful clinical trials.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeasabilityContent;
