import { useState } from 'react'; // Import Font Awesome icons

const Faqs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is a clinical trial?',
      answer:
        'A clinical trial is a research study that tests new medical approaches in people to determine their effectiveness, safety, and optimal usage.',
    },
    {
      question: 'How are participants selected for a clinical trial?',
      answer:
        'Participants are selected based on specific criteria, such as age, gender, the type and stage of a disease, previous treatment history, and other medical conditions.',
    },
    {
      question: 'What are the phases of a clinical trial?',
      answer:
        'Clinical trials are conducted in phases. Each phase is designed to answer different research questions. Phases include Phase I (testing safety), Phase II (testing efficacy), Phase III (comparing with current treatments), and Phase IV (long-term effectiveness).',
    },
    {
      question: 'How is my safety ensured during a clinical trial?',
      answer:
        'Participant safety is a top priority. Trials follow strict protocols, and are closely monitored by regulatory authorities, ethics committees, and the research team. Participants are informed about all risks and can leave the study at any time.',
    },
    {
      question: 'What happens after a clinical trial ends?',
      answer:
        'After a clinical trial ends, the collected data is analyzed to determine the outcomes of the intervention. Participants are often informed of the results, and if successful, the treatment may move forward in the regulatory approval process.',
    },
  ];

  return (
    <div className='max-w-3xl mx-auto p-6'>
      <h2 className='text-3xl font-bold text-center mb-6'>FAQs</h2>
      <div className='space-y-4'>
        {faqs.map((faq, index) => (
          <div key={index}>
            <button
              className='w-full text-left p-4  text-[#00075e] rounded-md flex justify-between items-center'
              onClick={() => toggleAccordion(index)}
            >
              <span className='font-semibold'>{faq.question}</span>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </button>
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                activeIndex === index ? 'max-h-screen' : 'max-h-0'
              }`}
            >
              <div className='p-4 bg-white text-gray-700'>{faq.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
