import { useParams } from 'react-router-dom';
import DetailsHeader from '../components/details/DetailsHeader';
import DetailsContent from '../components/DetailsContent';
import { useDocument } from '../hooks/useDocument';
import Spinner from '../utilities/Spinner';

const BlogDetails = () => {
  let { id } = useParams();

  const { docResponse } = useDocument('blogs', id);

  const { document, isPending } = docResponse;

  if (isPending) {
    return (
      <div className=' items-center flex justify-center h-[70vh] w-[95%] md:w-[70%] lg:w-[50%] mx-auto'>
        <Spinner loading={isPending} />
      </div>
    );
  }
  return (
    <main>
      <DetailsHeader document={document} />
      <DetailsContent document={document} />
    </main>
  );
};

export default BlogDetails;
