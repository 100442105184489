const Subtitle = ({ subtile, spanText }) => {
  return (
    <h3 className='text-[#00075e] leading-[1.2] text-[1.6rem] md:text-[1.8rem] my-2 font-semibold'>
      {subtile}
      <span className=' text-[#0264b0]'> {spanText}</span>
    </h3>
  );
};

export default Subtitle;
