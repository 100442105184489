import React from 'react';
import Button from '../../utilities/Button';
import Subtitle from '../home/Subtitle';
import Title from '../home/Title';
import { useNavigate } from 'react-router-dom';
import img from '../../public/images/img_3.png';
import img_2 from '../../public/images/img_7.png';

const MissionFirstSection = () => {
  const navigate = useNavigate();
  const clickHandler = () => navigate('/mission');
  return (
    <div className=' py-9'>
      <div className='w-[97%] md:w-[90%] lg:w-[80%] gap-6 md:gap-16 mx-auto grid grid-cols-1 md:grid-cols-2'>
        <div className=' rounded-tl-[2rem]  '>
          <img
            src={img}
            alt='image'
            className=' rounded-tl-[0rem] rounded-br-[6rem]   w-full h-[450px] object-cover'
          />
        </div>
        <div>
          <Title text='Evaluation of the feasibility' />
          <Subtitle
            spanText='Clinical Trials'
            subtile='Optimizing Feasibility and Training for'
          />
          <ul className=' list-disc '>
            <li className=' '>
              <span>
                write and validate a specific feasibility study questionnair
              </span>
            </li>
            <li>
              <span>
                selection and validation of investigation canters, in terms of
              </span>
              <ul className=' list-disc ml-5'>
                <li>
                  <span>Recruitment capacity</span>
                </li>
                <li>
                  <span>Technical and logistics resources</span>
                </li>
                <li>
                  <span>Experience in the conduct of clinical trials,</span>
                </li>
                <li>
                  <span>Motivation and time available for the project</span>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            This can be achieved in addition to the feasibility assessment
            performed by the sponsor itself in order to ensure good-quality, and
            reliable data.
            <br /> <span className='font-semibold'>Training</span> of
            investigators and nurses involved in the clinical trial.
          </p>
        </div>
      </div>

      <div className=' mt-20  w-[97%] md:w-[90%] lg:w-[80%] lg:gap-6 xl:gap-16 mx-auto grid gap-4 grid-cols-1 lg:grid-cols-2'>
        <div className=' order-2 lg:order-1'>
          <Title text='Regulatory affairs' />
          <p className='  text-justify mb-5'>
            Preparation and submission of EU Clinical Trial Applications,
            including IMPDs of all types and complexity. We can also arrange
            meetings with key worldwide regulatory authorities and support you
            in the preparation of the supporting documentation.
          </p>

          <Title text='Logistic organization' />
          <p className=' text-justify'>
            Pre selection visit (to outline the activities required to
            facilitate the investigational site selection process), Site
            initiation visit, Monitoring Visit and Close-Out Visit.
          </p>
        </div>
        <div className='order-1 lg:order-2 rounded-tl-[2rem]  '>
          <img
            src={img_2}
            alt='image'
            className=' rounded-tl-[0rem] rounded-bl-[6rem]   w-full h-[450px] object-cover'
          />
        </div>
      </div>
    </div>
  );
};

export default MissionFirstSection;
