import img from '../../public/images/banner_3.png';

const SelectionHeader = () => {
  return (
    <div
      style={{
        background: `linear-gradient(rgba(0, 16, 96, 0.750), rgba(0, 16, 96, 0.750)),url(${img})`,
        height: '40vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='w-[97%] md:w-[90%] lg:w-[50%]   flex-col mx-auto flex items-center h-full justify-center'>
        <h1 className=' text-[1.6rem] md:text-[2.5rem] text-[#fff] font-semibold'>
          Selection
        </h1>
        <p className=' text-[#fff] text-center'>
          Site selection is a rigorous process in conducting clinical trials.
          Factors that should influence investigator site selection include
        </p>
      </div>
    </div>
  );
};

export default SelectionHeader;
