import ApprochContent from '../components/approch/ApprochContent';
import PagesHeader from '../components/PagesHeader';
import img from '../public/images/banner_3.png';

const Approch = () => {
  return (
    <main>
      <PagesHeader
        desc='To meet the requirements of clinical research keindeya has established a team 
around a Scientific Committee.'
        title={'Approch'}
        img={img}
      />
      <ApprochContent />
    </main>
  );
};

export default Approch;
