import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { usePages } from './usePages';
import Header from '../utilities/Header';
import Footer from '../utilities/Footer';

const Routers = () => {
  const { publicUrl } = usePages();
  return (
    <BrowserRouter basname='/'>
      <Header />
      <Routes>
        {publicUrl.map(({ path, Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Routers;
