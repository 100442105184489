import img from '../public/images/banner_4.png';
import PagesHeader from '../components/PagesHeader';
import InstitutesAndDepartments from '../components/Selection/InstitutesAndDepartments';
import FooterContact from '../utilities/FooterContact';

const institutes = [
  {
    title: ' Site qualification and initiation',
    description:
      'Monitors ensure that trial sites meet the necessary requirements and are properly initiated before the start of the trial. ',
  },
  {
    title: 'Routine monitoring',
    description:
      'Monitors conduct regular visits to trial sites to monitor the progress of the trial, ensure protocol adherence, and address any issues that may arise.',
  },
  {
    title: 'Confirmation of subject eligibility',
    description:
      'Monitors verify that subjects meet the eligibility criteria outlined in the protocol before their enrolment in the trial. ',
  },
  {
    title: 'Documentation of informed consent',
    description:
      'Monitors review the informed consent documents signed by subjects to ensure that they have been properly informed about the trial.',
  },
  {
    title: 'Protocol adherence',
    description:
      'Monitors confirm that the trial is being conducted in accordance with the approved protocol and any amendments that may have been made during the course of the trial. ',
  },
  {
    title: 'Data collection and quality',
    description:
      'Monitors oversee the collection of data and ensure that it is accurate, complete, and of high quality',
  },
  {
    title: 'Source documentation review',
    description:
      'Monitors review the source documents to ensure that the data collected accurately reflects what happened during the trial. ',
  },
  {
    title: 'Study drug/device management and accountability',
    description:
      'Monitors track the usage of study drugs or devices to ensure proper management and accountability throughout the trial.',
  },
  {
    title: 'Adverse event review and compliance with reporting obligations',
    description:
      'Monitors review adverse events reported during the trial and ensure that they are being properly documented and reported as required by regulatory agencies. ',
  },
  {
    title: 'Compliance with Sponsor-Investigator Obligations',
    description:
      'Ensuring compliance with all obligations set forth by the sponsor and investigator.',
  },
  {
    title: 'Close-Out',
    description:
      'Monitors oversee the close-out process, ensuring that all required documentation is completed and that the trial site is properly closed in accordance with regulatory requirements. By performing these ongoing reviews and assessments, monitors help to ensure the integrity, quality, and compliance of clinical trials, ultimately contributing to the generation of reliable and meaningful data for the development of new medical treatments. ',
  },
];

const Monitoring = () => {
  return (
    <main>
      <PagesHeader title={'Monitoring'} img={img} />
      <InstitutesAndDepartments
        desc='To ensure that trials are conducted, recorded, and reported in accordance with the 
protocol, Standard Operating Procedures (SOPs), Good Clinical Practice (GCP), and 
the applicable regulatory requirements, monitors play a crucial role in overseeing 
various aspects of the trial process. Monitors are responsible for performing ongoing 
reviews of subject records and regulatory documents, which include'
        institutes={institutes}
      />
      <FooterContact />
    </main>
  );
};

export default Monitoring;
