import { Link } from 'react-router-dom';
import svg from '../../public/images/book.svg';
import svg_2 from '../../public/images/virus.svg';
import { formatDate } from '../../utils/formatDate';

const PublicationCard = ({ newsItem }) => {
  return (
    <Link
      to={`/publications/${newsItem.id}`}
      className=' transition-all duration-700 border-[1px] border-[#00065e72] rounded-md p-4'
    >
      <div className=' rounded-md h-[300px] w-full '>
        <img
          src={newsItem.imageUrl}
          alt='images'
          className=' rounded-md h-full w-full object-cover'
        />
      </div>
      <div className=' py-2 flex space-x-10'>
        <div className=' flex items-center  space-x-1'>
          <img src={svg} alt='svg' className=' h-5 w-5 object-cover' />
          <span className=' new-publisher text-[#00075e]  font-semibold text-[1rem]'>
            Dr.Kaba
          </span>
        </div>
        <div className=' flex items-center  space-x-1'>
          <span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={1.5}
              stroke='currentColor'
              className='size-6'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z'
              />
            </svg>
          </span>
          <span className=' capitalize text-[#00075e] font-semibold text-[1rem]'>
            {formatDate(Number(newsItem.createdAt))}
          </span>
        </div>
      </div>
      <div>
        <h2 className=' font-bold text-[#00075e] '>
          {newsItem.title.length > 50
            ? newsItem.title.substring(0, 50) + '...'
            : newsItem.title}
        </h2>

        <div
          className=' text-[0.9rem]'
          dangerouslySetInnerHTML={{
            __html:
              newsItem.description.length > 100
                ? String(newsItem.description.substring(0, 100)) + '...'
                : newsItem.description,
          }}
        />
        <div className=' justify-center py-1 flex items-center '>
          <button className='flex items-center px-5 py-1 rounded-full  bg-[#066196] text-[#fff] border-none'>
            <span>Read more </span>
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke-width='1.5'
                stroke='currentColor'
                class='size-6 text-[#fff] new-read-svg'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default PublicationCard;
