import FeasabilityContent from '../components/Feasability/FeasabilityContent';
import FeasabilityFooter from '../components/Feasability/FeasabilityFooter';
import PagesHeader from '../components/PagesHeader';
import img from '../public/images/banner_10.png';

const Feasability = () => {
  return (
    <main>
      <PagesHeader title={'Feasibility'} img={img} />
      <FeasabilityContent />
      <FeasabilityFooter />
    </main>
  );
};

export default Feasability;
