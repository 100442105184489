const NewHeader = () => {
  return (
    <div className='new_blogs'>
      <div className=' w-full flex items-center h-full justify-center'>
        <h1 className=' text-[2.5rem] text-[#fff] font-semibold'>News</h1>
      </div>
    </div>
  );
};

export default NewHeader;
