import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <main className=' h-full'>
      <div className=' justify-center h-full flex items-center flex-col'>
        <h1 className=' text-[3rem] font-bold'>404</h1>
        <p className=' text-[1.2rem]'>Ouup! No page found.</p>
        <button onClick={() => navigate('/')} className=' text-[1.2rem] mt-6'>
          Go to home
        </button>
      </div>
    </main>
  );
};

export default NotFound;
