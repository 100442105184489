import Faqs from '../components/vision/Faqs';
import FirstContent from '../components/vision/VisionFirstSection';
import VisionHeader from '../components/vision/VisionHeader';

const NotreVision = () => {
  return (
    <main>
      <VisionHeader />
      <FirstContent />
      <Faqs />
    </main>
  );
};

export default NotreVision;
