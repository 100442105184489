import React from 'react';

const ContactHeaderSection = () => {
  return (
    <section className='py-12 bg-[#f3f4f6]'>
      <div className=' w-[97%] md:w-[80%] xl:w-[70%] 2xl:w-[36%] mx-auto px-6'>
        <h2 className=' text-[1.2rem] md:text-3xl font-semibold text-start md:text-center mb-8 text-gray-800'>
          Contact Us for Clinical Trial Inquiries
        </h2>

        <div className=' flex flex-col space-y-6 justify-start'>
          <div className=' md:space-x-6 flex-col md:flex-row flex items-start md:items-center'>
            <span className=' block p-3 md:p-6 border-[2px] rounded-[1rem] border-[#0c7198]'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className=' h-6 w-6 md:h-10 md:w-10 text-[#0c7198]'
              >
                <path
                  fill-rule='evenodd'
                  d='M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z'
                  clip-rule='evenodd'
                />
              </svg>
            </span>
            <div>
              <h3 className=' text-[1.2rem] md:text-[1.6rem] font-semibold  text-[#0c7198]'>
                Téléphone
              </h3>
              <div className=' text-[1rem] font-semibold flex space-x-2'>
                <span>+33(0) 1 64 254 282 </span>
                <span> {'/'}</span>
                <span>+221 78 470 76 56</span>
              </div>
            </div>
          </div>
          <div className=' md:space-x-6 flex-col md:flex-row flex items-start md:items-center'>
            <span className=' block p-3 md:p-6 border-[2px] rounded-[1rem] border-[#0c7198]'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className=' h-6 w-6 md:h-10 md:w-10 text-[#0c7198]'
              >
                <path d='M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z' />
                <path d='M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z' />
              </svg>
            </span>
            <div>
              <h3 className=' text-[1.2rem] md:text-[1.6rem] font-semibold  text-[#0c7198]'>
                e-mail
              </h3>
              <div className=' text-[1rem] font-semibold flex space-x-2'>
                <span>sales@keindeya.com </span>
              </div>
            </div>
          </div>
          {/* <div className=' md:space-x-6 flex-col md:flex-row flex items-start md:items-center'>
            <span className=' block p-3 md:p-6 border-[2px] rounded-[1rem] border-[#0c7198]'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                className=' h-6 w-6 md:h-10 md:w-10 text-[#0c7198]'
              >
                <path
                  fillRule='evenodd'
                  d='m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
                  clipRule='evenodd'
                />
              </svg>
            </span>
            <div>
              <h3 className=' text-[1.2rem] md:text-[1.6rem] font-semibold  text-[#0c7198]'>
                Adresse
              </h3>
              <div className=' text-[1rem] font-semibold flex space-x-2'>
                <span>
                  32, avenue du Général de Gaulle
                  <br />
                  77220 TOURNAN EN BRIE{' '}
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ContactHeaderSection;
