import img from '../../public/images/banner.png';

const applicationDetails = [
  {
    title: 'Submit Your CV',
    description: 'Send your CV and cover letter to our HR department.',
    icon: '📄',
  },
  {
    title: 'Email Address',
    description: 'jobs@keindeya.com',
    icon: '✉️',
  },
];

const ApplicationDetails = () => {
  return (
    <section
      style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.3)),url(${img})`,

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
      }}
      className='py-12 bg-gray-100'
    >
      <div className='container mx-auto px-6'>
        <h2 className=' text-[1.3rem] md:text-3xl font-semibold text-start mb-8 text-white'>
          Application Details
        </h2>
        <div className='flex flex-col space-y-3 md:space-y-0  md:flex-row  justify-start   md:space-x-6 '>
          {applicationDetails.map((detail, index) => (
            <div
              key={index}
              className='text-white border-[1px] rounded-md border-[#ffffff6d]  flex  flex-col sm:flex-row  p-6  max-w-sm w-full'
            >
              <div className='text-4xl mb-4 text-center'>{detail.icon}</div>
              <div>
                <h3 className='text-xl font-semibold mb-2 text-center sm:text-start text-white'>
                  {detail.title}
                </h3>
                <p className=' text-center sm:text-start text-white'>
                  {detail.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ApplicationDetails;
