import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyB2dV7Xr6MA0naFOkoHGoYfnJnazc6UtLs',
  authDomain: 'keindeya-aeb55.firebaseapp.com',
  projectId: 'keindeya-aeb55',
  storageBucket: 'keindeya-aeb55.appspot.com',
  messagingSenderId: '1094783723544',
  appId: '1:1094783723544:web:bbfed1c51454ebd1ee7b6d',
};

// inti firebase

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, app };
